import Counter from "./Counter";
import FlightBooker from "./FlightBooker";
import Temperature from "./Temperature";
import Timer from "./Timer";
import Card from "./Card";
import Crud from "./Crud";

const SevenGuis = () => {
  return (
    <div className="grid xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 px-8 py-4">
      <div>
        <Card title="Counter">
          <Counter />
        </Card>
      </div>
      <div>
        <Card title="Temperature">
          <Temperature />
        </Card>
      </div>
      <div>
        <Card title="Flight Booker">
          <FlightBooker />
        </Card>
      </div>
      <div>
        <Card title="Timer">
          <Timer />
        </Card>
      </div>
      <div>
        <Card title="Crud">
          <Crud />
        </Card>
      </div>
      <div>
        <Card title="Circle Drawer">Coming Soon...</Card>
      </div>
      <div>
        <Card title="Cells">Coming Soon...</Card>
      </div>
    </div>
  );
};

export default SevenGuis;
