const Card = (props) => {
  return (
    <div className="rounded-t-md shadow-lg shadow-slate-600 bg-white">
      <div className="rounded-t-md h-10 bg-gradient-to-tr from-brand-yellow via-brand-red to-brand-purple text-white text-center p-2 font-bold">
        {props.title}
      </div>
      <div className="p-2">{props.children}</div>
    </div>
  );
};

export default Card;
