import { useEffect, useState } from "react";
import InputField from "./InputField";
import Button from "./Button";

const flightTypes = ["one-way flight", "return flight"];
const dateRegex = new RegExp(/\d{4}-\d{1,2}-\d{1,2}/);

const FlightBooker = () => {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [flightType, setFlightType] = useState(0);
  const [canSubmit, setCanSubmit] = useState(false);

  const changeFlightType = (e) => {
    setFlightType(e.target.value);
  };

  const validateDate = (d) => {
    return dateRegex.test(d);
  };

  useEffect(() => {
    if (flightType === 0) {
      setEnd("");
    }
  }, [flightType]);

  useEffect(() => {
    if (flightType === 0) {
      setCanSubmit(validateDate(start));
    } else if (!validateDate(start) || !validateDate(end)) {
      setCanSubmit(false);
    } else {
      setCanSubmit(start < end);
    }
  }, [flightType, start, end]);

  return (
    <div className="grid grid-rows-3 grid-cols-2">
      <div className="col-span-2 flex justify-center">
        <select
          name="flightType"
          onChange={changeFlightType}
          onBlur={changeFlightType}
          className="w-40 shadow-md my-2 border-none border-b-4 ring-1 mx-2 ring-cyan focus:outline-none focus:ring-pink"
        >
          {flightTypes.map((f) => (
            <option value={flightTypes.indexOf(f)} key={f}>
              {f}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-center">
        <InputField
          type="date"
          name="start"
          onChange={(e) => setStart(e.target.value)}
          value={start}
        />
      </div>
      <div className="flex justify-center">
        <InputField
          type="date"
          name="end"
          onChange={(e) => setEnd(e.target.value)}
          value={end}
          min={start}
          disabled={flightType === 0}
        />
      </div>
      <div className="col-span-2 flex justify-center">
        <Button disabled={!canSubmit} type="button">
          Book
        </Button>
      </div>
    </div>
  );
};

export default FlightBooker;
