import { useState } from "react";
import Button from "./Button";

const Counter = () => {
  const [count, setCount] = useState(0);

  return (
    <div className=" flex flex-col justify-center align-middle h-4/6">
      <div className="text-center font-bold text-lg">{count}</div>
      <div className="text-center">
        <Button onClick={() => setCount(count + 1)}>Count</Button>
      </div>
    </div>
  );
};

export default Counter;
