import { useEffect } from "react";
import { useState } from "react";
import Button from "./Button";

/*
start time
current time
seconds cur - start
slider
*/

const Timer = () => {
  const [slider, setSlider] = useState(15);
  const [startTime, setStartTime] = useState(new Date());
  const [timer, setTimer] = useState(0);

  const calcTime = (d) => {
    return Math.floor((new Date() - d) / 1000);
  };

  const reset = () => {
    setTimer(0);
    setStartTime(new Date());
  };

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer < slider) {
        setTimer(calcTime(startTime));
      }
    }, 1000);
    return () => clearTimeout(time);
  });

  useEffect(() => {
    if (slider < timer) {
      setTimer(slider);
      setStartTime(new Date() - slider * 1000);
      setTimer(calcTime(startTime));
    }
  }, [timer, startTime, slider]);

  return (
    <div>
      <div className="relative pt-1">
        <input
          type="range"
          min="0"
          max="30"
          value={slider}
          onChange={(e) => setSlider(e.target.value)}
          className="w-full text-pink bg-pink mt-3"
        />
        <div className="m-2 overflow-hidden h-6 text-xs flex justify-center rounded bg-white ring-1 ring-light-blue">
          <div className="absolute flex-center text-center text-purple font-bold text-lg leading-6">
            {timer}
          </div>
          <div
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-light-blue"
            style={{
              width: `${(timer / slider) * 100}%`,
            }}
          ></div>
        </div>
        <Button onClick={reset}>Reset</Button>
      </div>
    </div>
  );
};

export default Timer;
