const InputField = (props) => {
  return (
    <input
      {...props}
      className="w-40 shadow-md my-2 border-none border-b-4 ring-1 mx-2 ring-cyan focus:outline-none focus:ring-pink"
    />
  );
};

export default InputField;
