const Button = (props) => {
  return (
    <button
      type="button"
      {...props}
      className={`${props.className} disabled:cursor-default disabled:opacity-30 h-11 p-2 font-bold active:shadow-inner shadow-md rounded-md focus:outline-none border-b-4 active:border-b-0`}
    >
      {props.children}
    </button>
  );
};

export default Button;
