import { Link } from "react-router-dom";
import SevenGuis from "./sevenGUIs/SevenGuis";

function App() {
  return (
    <div className="grid grid-rows-[auto_1fr_auto] grid-cols-1 min-h-screen dark:bg-black">
      <header className="col-span-2">
        <img src="./jf_96x400.png" alt="" />
      </header>
      <main className="bg-brand-light-blue">
        <SevenGuis />
      </main>
      <div className="col-span-2 text-white">
        00
        <Link to="sevenguis">Seven GUIS</Link>
      </div>
    </div>
  );
}

export default App;
