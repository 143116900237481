import { useEffect, useState } from "react";
import Button from "./Button";
import InputField from "./InputField";
import { v4 as uuidv4 } from "uuid";

const Crud = () => {
  const [people, setPeople] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selected, setSelected] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    setSelected(people.length ? people[0].id : "");
  }, [people]);

  const createPerson = function () {
    setPeople([...people, { firstName, lastName, id: uuidv4() }]);
    setFirstName("");
    setLastName("");
  };

  const deletePerson = function () {
    setPeople([...people.filter((person) => person.id !== selected)]);
  };

  const updatePerson = function () {
    setPeople([
      ...people.map((person) => {
        if (person.id === selected) {
          return { firstName, lastName, id: person.id };
        }
        return person;
      }),
    ]);
    setFirstName("");
    setLastName("");
  };

  return (
    <div className="grid grid-cols-2 grid-rows-[auto_1fr_auto] gap-2">
      <div className="col-start-1 row-start-1">
        <label htmlFor="crud__filter">
          Filter Prefix:
          <InputField
            id="crud__filter"
            value={filter}
            onChange={function (e) {
              setFilter(e.target.value);
            }}
          />
        </label>
      </div>
      <div className="col-start-1 row-start-2">
        <select
          className="w-full shadow-md my-2 border-none border-b-4 ring-1 mx-2 ring-cyan focus:outline-none focus:ring-pink"
          size="6"
          value={selected}
          onChange={function (e) {
            setSelected(e.target.value);
          }}
        >
          {people
            .filter((person) => {
              return !filter || person.lastName.startsWith(filter);
            })
            .sort((a, b) => {
              const person1 = `${a.lastName.toUpperCase()}${a.firstName.toUpperCase()}`;
              const person2 = `${b.lastName.toUpperCase()}${b.firstName.toUpperCase()}`;
              if (person1 < person2) return -1;
              if (person2 > person1) return 1;
              return 0;
            })
            .map((person) => (
              <option value={person.id} key={person.id}>
                {person.lastName},{person.firstName}
              </option>
            ))}
        </select>
      </div>
      <div className="col-start-2 row-start-2 align-top flex flex-col">
        <label htmlFor="crud__firstName">
          First Name
          <InputField
            id="crud__firstName"
            value={firstName}
            onChange={function (e) {
              setFirstName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="crud__lastName">
          Last Name
          <InputField
            id="crud__lastName"
            value={lastName}
            onChange={function (e) {
              setLastName(e.target.value);
            }}
          />
        </label>
      </div>
      <div className="col-span-2 row-start-3 justify-self-start">
        <Button
          disabled={!firstName || !lastName}
          className="m-2"
          onClick={createPerson}
        >
          Create
        </Button>
        <Button disabled={!selected} onClick={updatePerson} className="m-2">
          Update
        </Button>
        <Button disabled={!selected} onClick={deletePerson} className="m-2">
          Delete
        </Button>
      </div>
    </div>
  );
};

export default Crud;
