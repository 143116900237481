import { useState } from "react";
import InputField from "./InputField";

const Temperature = () => {
  const [celsius, setCelsius] = useState("");
  const [fahrenheit, setFahrenheit] = useState("");
  return (
    <div className="flex flex-col h-4/6 items-center justify-between">
      <label className="text-lg " htmlFor="celsius">
        <InputField
          type="number"
          onChange={(e) => {
            setCelsius(e.target.value);
            setFahrenheit((e.target.value * (9 / 5) + 32).toFixed(1));
          }}
          id="celsius"
          value={celsius}
        />
        &deg;C
      </label>
      <label className="text-lg text-purple" htmlFor="fahrenheit">
        <InputField
          type="number"
          onChange={(e) => {
            setFahrenheit(e.target.value);
            setCelsius(((e.target.value - 32) * (5 / 9)).toFixed(1));
          }}
          id="fahrenheit"
          value={fahrenheit}
        />
        &deg;F
      </label>
    </div>
  );
};

export default Temperature;
